import request from '@/utils/request'

// 查询第三方用户绑定信息列表
export function listWechatInfo (query) {
  return request({
    url: '/system/wechatInfo/list',
    method: 'get',
    params: query
  })
}

// 查询第三方用户绑定信息详细
export function getWechatInfo (id) {
  return request({
    url: '/system/wechatInfo/' + id,
    method: 'get'
  })
}

// 新增第三方用户绑定信息
export function addWechatInfo (data) {
  return request({
    url: '/system/wechatInfo',
    method: 'post',
    data: data
  })
}

// 修改第三方用户绑定信息
export function updateWechatInfo (data) {
  return request({
    url: '/system/wechatInfo',
    method: 'put',
    data: data
  })
}

// 删除第三方用户绑定信息
export function delWechatInfo (id) {
  return request({
    url: '/system/wechatInfo/' + id,
    method: 'delete'
  })
}

// 导出第三方用户绑定信息
export function exportWechatInfo (query) {
  return request({
    url: '/system/wechatInfo/export',
    method: 'get',
    params: query
  })
}
