<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="用户ID,sys_user用户id" prop="userId" >
        <a-input v-model="form.userId" placeholder="请输入用户ID,sys_user用户id" />
      </a-form-model-item>
      <a-form-model-item label="第三方平台类型" prop="oauthType" >
        <a-select placeholder="请选择第三方平台类型" v-model="form.oauthType">
          <a-select-option v-for="(d, index) in oauthTypeOptions" :key="index" :value="d.dictValue" >{{ d.dictLabel }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="第三方uid、openid 第三方 uid 、openid 等" prop="oauthId" >
        <a-input v-model="form.oauthId" placeholder="请输入第三方uid、openid 第三方 uid 、openid 等" />
      </a-form-model-item>
      <a-form-model-item label="unionid QQ / 微信同一主体下 Unionid 相同" prop="unionid" >
        <a-input v-model="form.unionid" placeholder="请输入unionid QQ / 微信同一主体下 Unionid 相同" />
      </a-form-model-item>
      <a-form-model-item label="密码凭证  access_token (目前更多是存储在缓存里)" prop="credential" v-if="formType === 1">
        <a-input v-model="form.credential" placeholder="请输入密码凭证  access_token (目前更多是存储在缓存里)" />
      </a-form-model-item>
      <a-form-model-item label="标识是否绑定 0否1是" prop="bindFlag" >
        <a-radio-group v-model="form.bindFlag" button-style="solid">
          <a-radio-button v-for="(d, index) in bindFlagOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="所属租户ID" prop="tenantId" v-if="formType === 1">
        <a-input v-model="form.tenantId" placeholder="请输入所属租户ID" />
      </a-form-model-item>
      <a-form-model-item label="是否删除 0否1是" prop="status" >
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{ d.dictLabel }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getWechatInfo, addWechatInfo, updateWechatInfo } from '@/api/system/wechatInfo'

export default {
  name: 'CreateForm',
  props: {
    oauthTypeOptions: {
      type: Array,
      required: true
    },
    bindFlagOptions: {
      type: Array,
      required: true
    },
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        userId: null,
        oauthType: null,
        oauthId: null,
        unionid: null,
        credential: null,
        bindFlag: 0,
        tenantId: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        userId: [
          { required: true, message: '用户ID,sys_user用户id不能为空', trigger: 'blur' }
        ],
        oauthType: [
          { required: true, message: '第三方平台类型不能为空', trigger: 'change' }
        ],
        oauthId: [
          { required: true, message: '第三方uid、openid 第三方 uid 、openid 等不能为空', trigger: 'blur' }
        ],
        bindFlag: [
          { required: true, message: '标识是否绑定 0否1是不能为空', trigger: 'blur' }
        ],
        tenantId: [
          { required: true, message: '所属租户ID不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '是否删除 0否1是不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        userId: null,
        oauthType: null,
        oauthId: null,
        unionid: null,
        credential: null,
        bindFlag: 0,
        tenantId: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getWechatInfo(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateWechatInfo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addWechatInfo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
