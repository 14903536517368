var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "用户ID,sys_user用户id", prop: "userId" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入用户ID,sys_user用户id" },
                model: {
                  value: _vm.form.userId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "userId", $$v)
                  },
                  expression: "form.userId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "第三方平台类型", prop: "oauthType" } },
            [
              _c(
                "a-select",
                {
                  attrs: { placeholder: "请选择第三方平台类型" },
                  model: {
                    value: _vm.form.oauthType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "oauthType", $$v)
                    },
                    expression: "form.oauthType",
                  },
                },
                _vm._l(_vm.oauthTypeOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "第三方uid、openid 第三方 uid 、openid 等",
                prop: "oauthId",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入第三方uid、openid 第三方 uid 、openid 等",
                },
                model: {
                  value: _vm.form.oauthId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "oauthId", $$v)
                  },
                  expression: "form.oauthId",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            {
              attrs: {
                label: "unionid QQ / 微信同一主体下 Unionid 相同",
                prop: "unionid",
              },
            },
            [
              _c("a-input", {
                attrs: {
                  placeholder: "请输入unionid QQ / 微信同一主体下 Unionid 相同",
                },
                model: {
                  value: _vm.form.unionid,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "unionid", $$v)
                  },
                  expression: "form.unionid",
                },
              }),
            ],
            1
          ),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                {
                  attrs: {
                    label: "密码凭证  access_token (目前更多是存储在缓存里)",
                    prop: "credential",
                  },
                },
                [
                  _c("a-input", {
                    attrs: {
                      placeholder:
                        "请输入密码凭证  access_token (目前更多是存储在缓存里)",
                    },
                    model: {
                      value: _vm.form.credential,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "credential", $$v)
                      },
                      expression: "form.credential",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "标识是否绑定 0否1是", prop: "bindFlag" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.bindFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "bindFlag", $$v)
                    },
                    expression: "form.bindFlag",
                  },
                },
                _vm._l(_vm.bindFlagOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _vm.formType === 1
            ? _c(
                "a-form-model-item",
                { attrs: { label: "所属租户ID", prop: "tenantId" } },
                [
                  _c("a-input", {
                    attrs: { placeholder: "请输入所属租户ID" },
                    model: {
                      value: _vm.form.tenantId,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tenantId", $$v)
                      },
                      expression: "form.tenantId",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-form-model-item",
            { attrs: { label: "是否删除 0否1是", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: d.dictValue } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }